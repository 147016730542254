@import '../default/variables';


// Color Variables
$color-primary: #ff3b30;
$text-color: #2f2f2f;
$light-gray: #f0f0f0;
$gray: #ccc;
$dark-gray: #a6a6a6;
$special-color-1: #ffea00;

// Typography Variables
$font-primary: 'Open Sans', sans-serif;
